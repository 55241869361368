import React, { ReactElement } from 'react';
import SEO from '../components/seo';
import LoginForm from '../sections/LoginForm';

const Login = ({location}: any): ReactElement => {

    return (
        <>
            <SEO title='Login' description='Login to your Streamlux account.' />
            <LoginForm location={location} />
        </>
    );
};

export default Login;
